




































































































import { Component, Mixins } from 'vue-property-decorator';
import TableTreeComponent from '@common-src/mixins/table-tree-component';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { PermissionEntityModel, PermissionQueryModel } from '@common-src/entity-model/permission-entity';
import PermissionService from '@common-src/service/permission';
import MENUS from '@common-src/json/menu3.json';
import ImportPermissionDialog from './import-permission-dialog.vue';
import PermissionServiceDialog from './permission-service-dialog.vue';
import { ResourceType, CRUDQType, PermissionMediaType } from '@common-src/model/enum';
import AppService from '@common-src/service3/app';

/**
 * 资源最大层级
 */
const MAX_LEVEL = 4;

@Component({
    components: {
        'import-permission-dialog': ImportPermissionDialog,
        'permission-service-dialog': PermissionServiceDialog
    }
})
export default class PermissionListComponent extends Mixins(TableDialogFormComponent, TableTreeComponent) {
    PermissionEntityModel = PermissionEntityModel;
    PermissionMediaType = PermissionMediaType;
    permissionGroupList: Array<any> = null;
    selectedPermissionGroupId: Array<string> = null;
    selectedPermissionGroup: any = {};
    syncLoading: boolean = false;
    resourceType: ResourceType = ResourceType.PAGE;
    ResourceType = ResourceType;
    maxLevel = MAX_LEVEL;
    mediaType = PermissionMediaType.PC;

    created() {
        this.initTable({
            service: PermissionService,
            queryModel: new PermissionQueryModel(),
            tableColumns: PermissionEntityModel.getTableColumns()
        });
        PermissionService.getAllPermissionGroupV2().then(res => {
            this.permissionGroupList = res;
            if (this.permissionGroupList && this.permissionGroupList.length > 0) {
                this.mediaType = this.permissionGroupList[0]['mediaType'];
                this.selectedPermissionGroup = this.permissionGroupList[0]['groups'][0];
                this.selectedPermissionGroupId = [this.permissionGroupList[0]['groups'][0].id];
                (this.queryModel as PermissionQueryModel).group = this.permissionGroupList[0]['groups'][0].id;
            }
        });
    }

    get PermissionGroupData() {
        return _.find(this.permissionGroupList, item => this.mediaType === item.mediaType)?.groups;
    }

    get AppPermissionGroup() {
        return this.selectedPermissionGroup.name === '应用' && this.mediaType === PermissionMediaType.PC;
    }

    onMediaTypeChange() {
        if (_.isEmpty(this.PermissionGroupData)) {
            this.listData = [];
            return;
        }
        this.menuClick(this.PermissionGroupData[0]);
    }

    // 同步应用管理
    async asyncAppMenu() {
        const addParams = [];
        const updateParams = [];
        // 批量删除功能
        // let deleteParams = [];
        if (!this.AppPermissionGroup) {
            return;
        }
        this.startFullScreenLoading('正在同步...');
        AppService.getAllApps().then((res) => {
            const appPermissionList = _.filter(res, item => item.versionStatus === 'S');
            const currentAppIdentifiers = _.map(this.listData, item => item.identifier);
            const targetAppIdentifiers = _.map(appPermissionList, item => item.identifier);
            _.forEach(appPermissionList, item => {
                const param = {
                    'category': ResourceType.PAGE,
                    'groupId': _.get(this.selectedPermissionGroupId, [0]),
                    'identifier': item.identifier,
                    'name': item.name,
                    'mediaType': this.selectedPermissionGroup.mediaType,
                    'type': this.selectedPermissionGroup.type
                };
                const updateParam = _.filter(this.listData, el => item.identifier === el.identifier && item.name !== el.name);
                if (updateParam && updateParam.length > 0) {
                    Object.assign(param, { id: updateParam[0].id });
                    updateParams.push(param);
                }
                if (!_.some(this.listData, el => item.identifier === el.identifier)) {
                    addParams.push(param);
                }
            });
            // deleteParams = this.listData.filter(item => !targetAppIdentifiers.includes(item.identifier));
        }).then(() => {
            const promiseCreate = this.initAppMenu(addParams, CRUDQType.CREATE);
            const promiseUpdate = this.initAppMenu(updateParams, CRUDQType.UPDATE);
            // const promiseDelete = this.initAppMenu(deleteParams, CRUDQType.DELETE);
            Promise.all([promiseCreate, promiseUpdate]).then(() => {
                this.getList();
            });
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    get TableColumns() {
        if (this.resourceType === ResourceType.SERVICE) {
            return PermissionEntityModel.getServiceTableColumns();
        }
        return PermissionEntityModel.getTableColumns();
    }

    addPermissionClick() {
        const newPermissionEntity = new PermissionEntityModel();
        newPermissionEntity.groupModel = this.selectedPermissionGroup;
        this.addClick(newPermissionEntity);
    }
    editPermissionClick(record: PermissionEntityModel) {
        record.groupModel = this.selectedPermissionGroup;
        this.editClick(record, true);
    }

    editPermissionServiceClick(record: PermissionEntityModel) {
        if (record.category === ResourceType.FUNCTION) {
            this.showMessageWarning('功能上暂不支持配置服务资源');
            return;
        }
        (this.$refs.permissionServiceDialog as PermissionServiceDialog).dialogOpen(record.id, record.name);
    }

    addChildClick(record: PermissionEntityModel) {
        const childPermissionEntity = new PermissionEntityModel();
        childPermissionEntity.parent = record;
        childPermissionEntity.type = record.type;
        childPermissionEntity.groupModel = this.selectedPermissionGroup;
        this.addClick(childPermissionEntity);
    }

    menuClick(permissionGroup: any) {
        this.selectedPermissionGroup = permissionGroup;
        this.selectedPermissionGroupId = [permissionGroup.id];
        (this.queryModel as PermissionQueryModel).group = permissionGroup.id;
    }

    importPermissionClick() {
        const groupId = _.get(this.selectedPermissionGroup, 'id');
        (this.$refs.importPermissionDialog as ImportPermissionDialog).dialogOpen(groupId);
    }

    exportPermissionClick() {
        const groupId = _.get(this.selectedPermissionGroup, 'id');
        return PermissionService.exportPermission(groupId).then(() => {
            this.showMessageSuccess('导出成功');
        });
    }

    async syncWebMenu() {
        if (this.selectedPermissionGroup && this.selectedPermissionGroup.mediaType === 'PC') {
            this.startFullScreenLoading('正在同步...');
            try {
                await this.initWebMenu(MENUS);
            } catch (e) {
                this.stopFullScreenLoading();
            }
            this.stopFullScreenLoading();
            this.getList();
        } else {
            this.showMessageWarning('暂不支持同步');
        }
    }

    async initWebMenu(menus: Array<any>, resourceType: ResourceType = ResourceType.PAGE, parentId?: string, parentCode?: string) {
        for (let i = 0; i < menus.length; i++) {
            const menu = menus[i];
            if (menu.route !== '/dashboard/index') {
                let pId = null;
                let pCode = '';
                if (resourceType === ResourceType.FUNCTION || menu.permission.indexOf(this.selectedPermissionGroup.type) > -1) {
                    const addPermissioModel = new PermissionEntityModel();
                    addPermissioModel.name = menu.name;
                    if (parentCode) {
                        pCode = `${parentCode}:${menu.route}`;
                    } else {
                        pCode = menu.route;
                    }
                    addPermissioModel.identifier = pCode;
                    addPermissioModel.category = resourceType;
                    addPermissioModel.parentId = parentId;
                    addPermissioModel.groupModel = this.selectedPermissionGroup;
                    addPermissioModel.remark = '批量同步';
                    const res = await PermissionService.create(addPermissioModel);
                    pId = _.get(res, 'id');
                }
                if (menu.subMenus && menu.subMenus.length > 0) {
                    await this.initWebMenu(menu.subMenus, ResourceType.PAGE, pId, pCode);
                }
                if (menu.function && menu.function.length > 0 && pId) {
                    await this.initWebMenu(menu.function, ResourceType.FUNCTION, pId, pCode);
                }
            }
        }
    }

    // 循环同步应用列表
    async initAppMenu(params: Array<any>, type: CRUDQType, resourceType: ResourceType = ResourceType.PAGE) {
        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            const newPermissioModel = new PermissionEntityModel();
            newPermissioModel.identifier = param.identifier;
            newPermissioModel.category = resourceType;
            newPermissioModel.groupId = param.groupId;
            newPermissioModel.name = param.name;
            newPermissioModel.mediaType = param.mediaType;
            newPermissioModel.type = param.type;
            switch (type) {
                case CRUDQType.CREATE:
                    await PermissionService.create(newPermissioModel);
                    break;
                case CRUDQType.RETRIEVE:
                    break;
                case CRUDQType.UPDATE:
                    newPermissioModel.id = param.id;
                    await PermissionService.update(newPermissioModel);
                    break;
                case CRUDQType.DELETE:
                    newPermissioModel.id = param.id;
                    await PermissionService.delete(newPermissioModel);
                    break;
                case CRUDQType.QUERY:
                    break;
            }
        }
    }
}

